/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Button, Col, Row, Form,
} from 'react-bootstrap';
import { NumericFormat, PatternFormat } from 'react-number-format';
import DatePicker from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import {
//     TelegramShareButton,
//     WhatsappShareButton,
//     EmailShareButton,
//     TelegramIcon,
//     WhatsappIcon,
//     EmailIcon,
// } from 'react-share';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Navigation } from '../components/Navigation/index';
import { digits, formatPrice } from '../../../helpers/number';
import { today as TodayTime } from '../../../helpers/date';
import { Loading } from '../../../components/Loading';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * Add Reservation implementation
 */
export const AddReservation: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [reserveDone, setReserveDone] = useState(false);
    const [reserveLink, setReserveLink] = useState('');
    const [nightCount, setNightCount] = useState<any>(0);
    const [roomList, setRoomList] = useState([]);
    const [pricesResource, setPricesResource] = useState({
        per_person: 0,
        additions : 0,
    });
    const [model, setModel] = useState({
        room_id              : null,
        start_date           : '',
        end_date             : '',
        number_of_guest      : 0,
        number_of_extra_guest: 0,
        first_name           : '',
        last_name            : '',
        phone_number         : '',
        payable_amount       : 0,
        total_price          : 0,
        finalized            : true,
    });
    const navigate = useNavigate();
    // const title = 'Please complete your reservation 😀';

    /**
     * Submit reservation form
     */
    const submitForm = () => {
        setLoading(true);
        $services.Reservation.create({
            ...model,
            phone_number  : model.phone_number.toString().replaceAll(' ', ''),
            payable_amount: model.payable_amount.toString().replace(/,/g, ''),
        }).then((response: any) => {
            setReserveLink(`${window.location.origin}/sr/${response.data.id}`);
            setReserveDone(true);
            toast.success('Reserve has been submitted.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Fetch room list
     */
    const fetch = () => {
        setFetchLoading(true);
        return $services.Room.list().then((response: any) => {
            setRoomList(response.data);
            setModel((state: any) => ({
                ...state,
                room_id: response.data[0].id,
            }));
            setPricesResource({
                per_person: response.data[0].cost,
                additions : response.data[0].additional_guest_cost,
            });
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setFetchLoading(false);
        });
    };

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            fetch();
        } else {
            navigate('/auth/login');
        }
    }, []);

    return (
        <Loading active={ fetchLoading }>
            <div className="m-dashboard m-dashboard__add-reservation">
                <Helmet>
                    <title>Add Reservation</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">Add Reservation 🌚</h3>
                </header>

                <Row className="m-dashboard__room-list">
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Room:
                            </Form.Label>
                            <Form.Select
                                aria-label="Room select"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        room_id: event.target.value,
                                    }));
                                    const find: any = roomList.find((r: any) => r.id === event.target.value);
                                    if (find) {
                                        setPricesResource({
                                            per_person: find.cost,
                                            additions : find.additional_guest_cost,
                                        });
                                    }
                                } }
                            >
                                {
                                    roomList.map((room: any) => (
                                        <option
                                            value={ room.id }
                                            key={ room.id }
                                        >
                                            { room.name }
                                        </option>
                                    ) )
                                }
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Start date ~ End date:
                            </Form.Label>
                            <DatePicker
                                calendar={ persian }
                                locale={ persian_fa }
                                disabled={ reserveDone }
                                plugins={ [weekends()] }
                                range
                                calendarPosition="bottom-right"
                                onChange={ (event: any) => {
                                    if (event.length === 2) {
                                        const days = get(event, '1.dayOfBeginning') - get(event, '0.dayOfBeginning') + 1;
                                        setNightCount(days);
                                        setModel((state: any) => ({
                                            ...state,
                                            start_date    : digits(event[0]?.toDate().toLocaleDateString('fa-IR').replaceAll('/', '-')),
                                            end_date      : digits(event[1]?.toDate().toLocaleDateString('fa-IR').replaceAll('/', '-')),
                                            payable_amount: ((model.number_of_guest * pricesResource.per_person)
                                            + (model.number_of_extra_guest * pricesResource.additions)) * days,
                                            total_price: ((model.number_of_guest * pricesResource.per_person)
                                            + (model.number_of_extra_guest * pricesResource.additions)) * days,
                                        }));
                                    }
                                } }
                                mapDays={ ({
                                    date, today, isSameDate, selectedDate,
                                }: any) => {
                                    const props: any = {};
    
                                    if (date.dayOfBeginning < today.dayOfBeginning) {
                                        return { disabled: true };
                                    }
    
                                    if (isSameDate(date, selectedDate)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#FFFFFF',
                                            color          : '#424242',
                                        };
                                    }
    
                                    return props;
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Guests number:
                            </Form.Label>
                            <Form.Control
                                type="number"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        number_of_guest: event.target.value,
                                        payable_amount : ((event.target.value * pricesResource.per_person)
                                    + (model.number_of_extra_guest * pricesResource.additions)) * nightCount,
                                        total_price: ((event.target.value * pricesResource.per_person)
                                    + (model.number_of_extra_guest * pricesResource.additions)) * nightCount,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Additional Guests:
                            </Form.Label>
                            <Form.Control
                                type="number"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        number_of_extra_guest: event.target.value,
                                        payable_amount       : ((model.number_of_guest * pricesResource.per_person)
                                    + (event.target.value * pricesResource.additions)) * nightCount,
                                        total_price: ((model.number_of_guest * pricesResource.per_person)
                                    + (event.target.value * pricesResource.additions)) * nightCount,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                First Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        first_name: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Last Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        last_name: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                National code:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        national_code: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Phone number:
                            </Form.Label>
                            <PatternFormat
                                className="form-control"
                                disabled={ reserveDone }
                                format="#### ### ####"
                                allowEmptyFormatting
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        phone_number: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Payable amount:
                            </Form.Label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator=","
                                allowLeadingZeros
                                value={ model.payable_amount }
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        payable_amount: event.target.value,
                                    }));
                                } }
                            />
                            <Form.Text>
                                This can be part of the total cost or all of it. (like Deposit)
                            </Form.Text>
                        </div>
                    </Col>
                    {/* TODO: use this after create user form */}
                    {/* <Col md="12">
                        <div className="form-section">
                            <Form.Check
                                label="Do you want to finalize?"
                                id="finalize-reservation"
                                disabled={ reserveDone }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        finalized: event.target.checked,
                                    }));
                                } }
                            />
                        </div>
                    </Col> */}
                    {/* TODO add national card */}
                    {/* <Col md="12">
                    <div className="form-section">
                        <Form.Label className="form-section__label">
                            National card:
                        </Form.Label>
                        <Form.Control
                            type="file"
                            accept=".jpg,.png,.pdf"
                        />
                    </div>
                </Col> */}
                    <Col md="12">
                        <Button
                            variant="light"
                            disabled
                            className="cost w-100"
                        >
                            <span>
                                { formatPrice(model.total_price) }
                            </span>
                        </Button>
                    </Col>

                    <Col md="12">
                        { !reserveLink ? (
                            <Button
                                variant="primary"
                                disabled={ isLoading }
                                className="w-100"
                                onClick={ submitForm }
                            >
                                { isLoading ? 'Loading…' : 'Submit' }
                            </Button>
                        ) : (
                            <>
                                {/* TODO: Use to share user form */}
                                {/* <CopyToClipboard
                                    text={ reserveLink }
                                    onCopy={ () => {
                                        toast.success('Copied to clipboard!', { position: toast.POSITION.BOTTOM_RIGHT });
                                    } }
                                >
                                    <Button
                                        variant="outline-success"
                                        className="w-100"
                                    >
                                        { reserveLink }
                                    </Button>
                                </CopyToClipboard> */}
                                <Button
                                    variant="warning"
                                    className="w-100"
                                    onClick={ () => {
                                        window.location.reload();
                                    } }
                                >
                                    Clear Form
                                </Button>
                            </>
                        ) }
                    </Col>

                    {/* TODO: Use to share user form */}
                    {/* { reserveLink && (
                        <Col md="12">
                            <div className="share-links">
                                <TelegramShareButton
                                    url={ reserveLink }
                                    title={ title }
                                >
                                    <TelegramIcon size={ 32 } />
                                </TelegramShareButton>

                                <WhatsappShareButton
                                    url={ reserveLink }
                                    title={ title }
                                    separator=":: "
                                >
                                    <WhatsappIcon size={ 32 } />
                                </WhatsappShareButton>

                                <EmailShareButton
                                    url={ reserveLink }
                                    subject={ title }
                                    body="body"
                                >
                                    <EmailIcon size={ 32 } />
                                </EmailShareButton>
                            </div>
                        </Col>
                    )} */}
                </Row>

                <Navigation />
            </div>
        </Loading>
    );
};
